import React from 'react'
import TopBar from './components/TopBar'
import Header from './components/Header'
import Footer from './components/Footer'

export default function BurnToken() {
    return (
        <>
            <TopBar />
            <Header />
            <section className="min-h-[calc(100vh-212px)] w-full px-[16px]">
                <div className="max-w-[520px] mx-auto w-full flex flex-col items-center sm-480:gap-[48px] gap-[30px] self-stretch sm-480:py-[38px] py-[48px] ">
                    <div className="flex flex-col items-center gap-2 self-stretch ">
                        <p className="text-dark-col sm-480:text-[36px] text-[32px] font-medium sm-480:leading-[92%] leading-[100%] tracking-[-1.08px] capitalize">Convert Burn Token</p>
                        <p className="text-dark-col sm-480:text-[16px] text-[14px] font-medium sm-480:leading-[120%] leading-[100%] tracking-[-0.32px] capitalize">Convert your burn token Into LYC</p>
                    </div>
                    <div className="flex flex-col items-center gap-2   justify-center self-stretch">
                        <div className="flex flex-col items-center gap-2 self-stretch relative justify-center ">
                            <div className="flex sm-480:p-[30px] p-[16px] flex-col items-start gap-4 sm-480:rounded-[30px] rounded-[16px] bg-white self-stretch">
                                <div className="flex px-[16px] py-[4px] justify-center  items-center gap-2.5 rounded-[80px] bg-main-bg text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">From</div>
                                <div className="flex flex-col gap-2 self-stretch">
                                    <div className="grid grid-cols-2 ">
                                        <div className="flex items-center gap-2 ">
                                            <img className="sm-480:w-fit w-[48px]" src="assets/images/burn.svg" alt="burn" />
                                            <p className="text-dark-col sm-480:text-[40px] text-[36px] font-normal leading-[80%] tracking-[-0.8px] capitalize">BURN</p>
                                        </div>
                                        <input className="text-dark-col sm-480:text-[40px] text-[36px] font-normal leading-[80%] tracking-[-0.8px] p-0 focus:ring-0  text-right capitalize border-none " type="text" placeholder={"00"} />
                                    </div>
                                    <div className="grid grid-cols-2  gap-2.5">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">Burner</p>
                                        <p className="text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize">~$ 50532.2</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex sm-480:p-[30px] p-[16px] flex-col items-start gap-4 sm-480:rounded-[30px] rounded-[16px] bg-white self-stretch">
                                <div className="flex px-[16px] py-[4px] justify-center  items-center gap-2.5 rounded-[80px] bg-main-bg text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">To</div>
                                <div className="flex flex-col gap-2 self-stretch">
                                    <div className="grid grid-cols-2 ">
                                        <div className="flex items-center gap-2 ">
                                            <img className="sm-480:w-fit w-[48px]" src="assets/images/lyc.svg" alt="lyc" />
                                            <p className="text-dark-col sm-480:text-[40px] text-[36px] font-normal leading-[80%] tracking-[-0.8px] capitalize">LYC</p>
                                        </div>
                                        <input className="text-dark-col sm-480:text-[40px] text-[36px] font-normal leading-[80%] tracking-[-0.8px] p-0 focus:ring-0  text-right capitalize border-none " type="text" placeholder={"00"} />
                                    </div>
                                    <div className="grid grid-cols-2  gap-2.5">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">Lycanchain</p>
                                        <p className="text-dark-col/70 text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize">~$ 50532.2 <span className="text-dark-org ml-2">(-0.28%)</span></p>
                                    </div>
                                </div>
                                <div className="flex p-4 flex-col justify-center items-center gap-2 self-stretch rounded-[10px] bg-main-bg">
                                    <div className="grid grid-cols-2 self-stretch">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">Burning Ratio</p>
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize">1.2%</p>
                                    </div>
                                    <div className="grid grid-cols-2 self-stretch">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">Fee</p>
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize">1230 BURN</p>
                                    </div>
                                    <div className="grid grid-cols-2 self-stretch">
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">Burn Token</p>
                                        <p className="text-dark-col text-[14px] font-medium leading-[120%] text-right tracking-[-0.28px] capitalize">23521 BURN (~$ 1554.3)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute sm-480:top-[38%] top-[36%] left-1/2 cursor-pointer transform translate-x-[-50%] translate-y-[-50%]">
                                <img src="assets/images/downarrow.svg" alt="Convert" />
                            </div>
                        </div>
                        <button className="relative overflow-hidden custom-btn flex px-[30px] py-[20px] justify-center items-center rounded-[60px] bg-dark-col text-main-bg sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[0.8px] uppercase">
                            Convert
                            <div className="shape3" />
                            <div className="shape4" />
                        </button>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
