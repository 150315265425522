import React from 'react'

export default function TopBar() {
    return (
        <section className="flex py-4 justify-center items-center gap-2.5 self-stretch bg-topbar-bg">
            <img src="assets/images/star.svg" alt="star" />
            <p className="text-dark-col text-[14px] font-normal leading-[92%] tracking-[-0.28px] capitalize">
                More then 1M+ people earning LYC
            </p>
        </section>

    )
}
