import React from 'react'

export default function Footer() {
    return (
        <section className="w-full bg-dark-col sm:px-[40px] px-[16px]">
            <div className="max-w-[1240px] mx-auto w-full  flex justify-between md:flex-row flex-col md:items-center items-start gap-5 sm:py-[24px] py-[16px]">
                <p className="text-white/70  sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]">
                    © 2024 FlameSrike Protocol
                </p>
                <div className="flex md:items-center items-start md:flex-row flex-col md:gap-6 gap-4">
                    <a className="text-white/70  sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]" href="/">About Us</a>
                    <a className="text-white/70  sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]" href="/">Contact Us</a>
                    <a className="text-white/70  sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]" href="/">Terms &amp; Conditions</a>
                    <a className="text-white/70  sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]" href="/">Privacy Policy</a>
                </div>
            </div>
        </section>

    )
}
