import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <nav className="relative w-full z-20 bg-main-bg">
            <div className="flex flex-wrap items-center justify-between py-[16px] lg-1240:px-[80px] sm:px-[40px] px-[16px]  self-stretch">
                <Link to="/" className="flex-1">
                    <img src="assets/images/logo.svg" className="h-[50px]" alt="Flowbite Logo" />
                </Link>
                <div className="flex lg:order-2 space-x-3 lg:space-x-0 items-center rtl:space-x-reverse">
                    <Link to="/convert-token" className="relative overflow-hidden custom-btn lg:ml-[48px] ml-[20px] flex sm-562:px-[30px] px-[26px] sm-562:py-[20px] py-[15px] justify-center items-center gap-2.5 rounded-[60px] bg-dark-col text-main-bg sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[0.8px] uppercase">
                        Burner
                        <div className="shape3" />
                        <div className="shape4" />
                    </Link>
                    <button data-collapse-toggle="navbar-sticky" type="button" className="relative overflow-hidden custom-btn inline-flex items-center p-2 w-10 h-10 justify-center transition duration-300 text-sm text-gray-900 bg-light-org rounded-lg lg:hidden border border-or-btn focus:ring-[2px] ring-light-org" aria-controls="navbar-sticky" aria-expanded="false">
                        <div className="shape3" />
                        <div className="shape4" />
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                </div>
                <div className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1" id="navbar-sticky">
                    <ul className="flex flex-col lg:px-0 px-[20px] pl-[16px] lg:py-[20px] py-[20px] lg:relative sm:right-[40px] right-[13px] items-end lg:mt-0 mt-2 rounded-[30px] absolute lg:bg-transparent bg-or-btn lg:p-0 lg:gap-[48px] gap-[10px] lg:shadow-none shadow-2xl lg:flex-row  lg:border-0 lg:border-none border-[2px] border-light-org">
                        <li className="lg:p-0 p-2 lg:border-none border border-dark-col hover:border-light-org transition duration-300   lg:px-0 px-4 lg:bg-transparent lg:hover:bg-transparent hover:bg-dark-col rounded-[40px]">
                            <a href="#about" className="lg:text-dark-col text-white sm-562:text-[16px] text-[14px] font-medium leading-[92%]  tracking-[-0.32px] capitalize" aria-current="page">About Us</a>
                        </li>
                        <li className="lg:p-0 p-2 lg:border-none border border-dark-col hover:border-light-org transition duration-300   lg:px-0 px-4 lg:bg-transparent lg:hover:bg-transparent hover:bg-dark-col rounded-[40px]">
                            <a href="#hunters-game" className="lg:text-dark-col text-white sm-562:text-[16px] text-[14px] font-medium leading-[92%]  tracking-[-0.32px] capitalize">Hunters
                                Game</a>
                        </li>
                        <li className="lg:p-0 p-2 lg:border-none border border-dark-col hover:border-light-org transition duration-300   lg:px-0 px-4 lg:bg-transparent lg:hover:bg-transparent hover:bg-dark-col rounded-[40px]">
                            <a href="#how-work" className="lg:text-dark-col text-white sm-562:text-[16px] text-[14px] font-medium leading-[92%]  tracking-[-0.32px] capitalize">How
                                It Works</a>
                        </li>
                        <li className="lg:p-0 p-2 lg:border-none border border-dark-col hover:border-light-org transition duration-300   lg:px-0 px-4 lg:bg-transparent lg:hover:bg-transparent hover:bg-dark-col rounded-[40px] ">
                            <a href="#submit-token" className="lg:text-dark-col text-white sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[-0.32px] capitalize">Submit
                                Token</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}
