import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import TopBar from './components/TopBar'

export default function Home() {
    return (
        <>
            <TopBar />
            <Header />
            {/* section two */}
            <section className="flex sm:px-[40px] px-[16px] pt-[48px]  flex-col self-stretch items-center relative">
                <div className="flex pt-[24px] flex-col z-10 items-center gap-[24px] self-stretch">
                    <h1 className="text-dark-col text-center md-991:text-[96px] sm-562:text-[66px] sm-420:text-[50px] text-[10.6vw] font-medium leading-[92%] tracking-[-2.88px] capitalize">
                        Turn Token Burning <br />
                        into Real Gains!
                    </h1>
                    <p className="text-dark-col text-[14px] text-center font-medium leading-[120%] tracking-[-0.36px] capitalize">
                        Join the future of tokenomics where burning means Earning.
                    </p>
                    <a href='https://t.me/WerewolfHunters_bot' target='_blank' className="relative overflow-hidden custom-btn flex sm-562:px-[40px] px-[34px] sm-562:py-[25px] py-[20px] justify-center items-center gap-2.5 rounded-[60px] bg-or-btn text-main-bg sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[0.8px] uppercase">
                        Join Now
                        <div className="shape3" />
                        <div className="shape4" />
                    </a>
                </div>
                <img className="md-991:w-fit " src="assets/images/hero-bg.png" alt="" />
                <img className="absolute bottom-0 z-10" src="assets/images/hero-bg-shad.png" alt="" />
                <img className="absolute md-991:top-[342px] sm-562:top-[286px] top-[248px]" src="assets/images/shad-line.png" alt="" />
            </section>
            {/* section two end */}
            {/* section three start */}
            <section id="how-work" className="max-w-[1240px] mx-auto w-full sm:px-[40px] px-[16px]">
                <div className="w-full h-full grid sm:grid-cols-3 grid-cols-1 md-991:gap-[48px] gap-[28px]">
                    <div className="flex flex-col justify-center items-center gap-4">
                        <img src="assets/images/burn-token.svg" alt="" />
                        <div className="py-[8px] text-center">
                            <p className="text-dark-col mb-2 text-[24px] font-medium leading-[92%] tracking-[-0.72px] capitalize">
                                Tap to Burn Token
                            </p>
                            <p className="text-dark-col/60 text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">
                                Tap quickly to burn tokens. The more you tap, the more tokens you
                                burn.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                        <img src="assets/images/earn-rewards.svg" alt="" />
                        <div className="py-[8px] text-center">
                            <p className="text-dark-col mb-2 text-[24px] font-medium leading-[92%] tracking-[-0.72px] capitalize">
                                Earn Rewards
                            </p>
                            <p className="text-dark-col/60 text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">
                                Get rewards based on how often and how many tokens you burn.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                        <img src="assets/images/special-events.svg" alt="" />
                        <div className="py-[8px] text-center">
                            <p className="text-dark-col mb-2 text-[24px] font-medium leading-[92%] tracking-[-0.72px] capitalize">
                                Join Special Events
                            </p>
                            <p className="text-dark-col/60 text-[14px] font-medium leading-[120%] tracking-[-0.28px] capitalize">
                                Take part in events to earn extra rewards and maximize your gains.
                            </p>
                        </div>
                    </div>
                </div>
                <div id="hunters-game" className="sm:py-[116px] sm-420:py-[84px] py-[60px] ">
                    <p className="text-dark-col md:text-[48px] sm-562:text-[42px] sm-420:text-[32px] text-[28px] font-medium leading-[110%] flex-1 tracking-[-1.44px] capitalize text-center">
                        A new way to manage token <br className="md-991:flex hidden" />
                        supply through fun, competitive, <br className="md-991:flex hidden" />
                        and rewarding gameplay.
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center gap-[24px] self-stretch">
                    <div className="grid lg-1240:grid-cols-2 grid-cols-1 sm:p-[48px] sm-420:p-[32px] p-[26px] md-991:overflow-x-visible overflow-x-hidden items-center gap-[48px] relative self-stretch rounded-[48px] bg-card-org-lin">
                        <div className="flex flex-col justify-between md:gap-[134px] sm-420:gap-[50px] gap-[30px] items-start self-stretch">
                            <div className>
                                <p className="text-white sm-420:mb-[16px] mb-[12px] md:text-[48px] sm-420:text-[42px] text-[38px] font-medium leading-[110%] tracking-[-1.44px] capitalize">
                                    Solving <br />
                                    Token Inflation
                                </p>
                                <p className="text-white/70 sm-562:text-[18px] sm-420:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.36px]">
                                    Traditional token burning methods lack engagement and fail to
                                    incentivize holders to participate actively. FlameStrike offers
                                    a solution by rewarding users for every token burned.
                                </p>
                                <p className="text-white/70 sm-562:text-[18px] sm-420:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.36px]">The First real use case of the FlameStrike Protocol is Werewolf Hunters.</p>
                            </div>
                            <a href='https://t.me/WerewolfHunters_bot' target='_blank' className="relative  overflow-hidden custom-btn flex sm-562:px-[40px] px-[34px] sm-562:py-[25px] py-[20px] justify-center items-center gap-2.5 rounded-[60px] bg-white text-or-btn sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[0.8px] uppercase">
                                Play Now
                                <div className="shape3" />
                                <div className="shape4" />
                            </a>
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="lg-1240:absolute right-[48px] bottom-[-1px] lg-1240:mb-0 sm:mb-[-48px] sm-420:mb-[-32px] mb-[-26px]" src="assets/images/token-inflation.png" alt="" />
                        </div>
                    </div>
                    <div className="grid md-991:grid-cols-2 grid-cols-1 gap-[24px] md-991:pb-[188px] sm-420:pb-[144px] pb-[116px] ">
                        <div className="rounded-[48px] bg-light-org sm:h-[490px] sm-480:h-[460px] sm-420:h-[420px] h-[360px] relative sm:p-[48px] sm-420:p-[32px] p-[26px] md-991:mb-0 mb-[150px]">
                            <p className="text-dark-col sm-420:mb-[16px] mb-[14px] md:text-[48px] sm-420:text-[42px] text-[38px] font-medium leading-[110%] tracking-[-1.44px] capitalize">
                                User <br />
                                Engagement
                            </p>
                            <p className="text-dark-col sm-562:text-[18px] sm-420:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.36px]">
                                By incorporating fun, competitive gameplay, the protocol fosters
                                active community involvement and increases token utility.
                            </p>
                            <img className="absolute bottom-0 left-[50%] transform translate-x-[-50%] translate-y-[44%]" src="assets/images/engagement.png" alt="" />
                        </div>
                        <div className="rounded-[48px] bg-or-btn sm:h-[490px] sm-480:h-[460px] sm-420:h-[420px] h-[360px] relative sm:p-[48px] sm-420:p-[32px] p-[26px]">
                            <p className="text-white sm-420:mb-[16px] mb-[12px] md:text-[48px] sm-420:text-[42px] text-[38px] font-medium leading-[110%] tracking-[-1.44px] capitalize">
                                Customizable
                                <br />
                                &amp; Scalable
                            </p>
                            <p className="text-white/70 sm-562:text-[18px] sm-420:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.36px]">
                                Token owners can tailor the burn rates, rewards, and game
                                elements, allowing for a flexible integration into various
                                blockchain ecosystems.
                            </p>
                            <img className="absolute bottom-0 left-[50%] transform translate-x-[-50%] translate-y-[44%]" src="assets/images/scalable.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="py-[48px] grid grid-cols-1 sm-420:gap-[48px] gap-[30px]">
                    <p className="text-dark-col md:text-[48px] sm-420:text-[42px] text-[38px] font-medium sm-420:leading-[110%] leading-[100%] tracking-[-1.44px] capitalize">
                        Benefits for Players
                    </p>
                    <div className="grid lg-1240:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[24px]">
                        <div className="flex sm:p-[48px] p-[32px] bg-white flex-col justify-between items-start rounded-[48px] sm-420:gap-[115px] gap-[44px]">
                            <img src="assets/images/bene-card-one.svg" alt="" />
                            <div className>
                                <p className="text-dark-col mb-[16px] text-[24px] font-medium leading-[110%] tracking-[-0.72px] capitalize">
                                    Earn Rewards
                                </p>
                                <p className="text-dark-col/70  sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]">
                                    Every burn helps reduce the token supply while earning you
                                    token-based rewards.
                                </p>
                            </div>
                        </div>
                        <div className="flex sm:p-[48px] p-[32px] bg-white flex-col justify-between items-start rounded-[48px] sm-420:gap-[115px] gap-[44px]">
                            <img src="assets/images/competition.svg" alt="" />
                            <div className>
                                <p className="text-dark-col mb-[16px] text-[24px] font-medium leading-[110%] tracking-[-0.72px] capitalize">
                                    Real-Time Competition
                                </p>
                                <p className="text-dark-col/70  sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]">
                                    Compete with other players to burn the most tokens and climb the
                                    leaderboards.
                                </p>
                            </div>
                        </div>
                        <div className="flex sm:p-[48px] p-[32px] bg-white flex-col justify-between items-start rounded-[48px] sm-420:gap-[115px] gap-[44px]">
                            <img src="assets/images/boost-game.svg" alt="" />
                            <div className>
                                <p className="text-dark-col mb-[16px] text-[24px] font-medium leading-[110%] tracking-[-0.72px] capitalize">
                                    Boost Your Game
                                </p>
                                <p className="text-dark-col/70  sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]">
                                    Special events and booster packs offer opportunities to increase
                                    your rewards.
                                </p>
                            </div>
                        </div>
                        <div className="flex sm:p-[48px] p-[32px] bg-white flex-col justify-between items-start rounded-[48px] sm-420:gap-[115px] gap-[44px]">
                            <img src="assets/images/transparent-fair.svg" alt="" />
                            <div className>
                                <p className="text-dark-col mb-[16px] text-[24px] font-medium leading-[110%] tracking-[-0.72px] capitalize">
                                    Transparent &amp; Fair
                                </p>
                                <p className="text-dark-col/70  sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]">
                                    Powered by blockchain, every action is transparent, secure, and
                                    verifiable.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* section three end */}
            {/* section four start */}
            <section className="w-full bg-white">
                <div className="max-w-[1240px] mx-auto w-full grid grid-cols-1 sm:px-[40px] px-[16px] gap-[48px] py-[96px]">
                    <div className="grid md-991:grid-cols-2 grid-cols-1  sm-420:gap-[48px] gap-[36px]">
                        <div className="flex justify-center">
                            <img src="assets/images/build-com.svg" alt="" />
                        </div>
                        <div className="flex sm-562:px-[48px] px-[16px] sm-420:gap-[24px] gap-[16px] flex-col justify-center md-991:items-start items-center">
                            <div className="flex flex-col md-991:items-start items-center md-991:text-start text-center sm-420:gap-4 gap-2">
                                <div className="flex px-[16px] py-[8px] justify-center items-center gap-2.5 rounded-[80px] border-[1.5px] border-light-org text-dark-col sm-562:text-[16px] text-[14px] font-medium leading-[110%] tracking-[-0.48px] capitalize">
                                    Build your Community!
                                </div>
                                <p className="text-dark-col md:text-[48px] sm-562:text-[42px] text-[34px] font-medium leading-[110%] tracking-[-1.44px] capitalize">
                                    Project Owners
                                </p>
                                <p className="text-dark-col/70 sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]">
                                    List Tasks to Attract Community &amp; Add Your Token for Burning
                                    Want to attract more engagement for your project or token? With
                                    FlameStrike Protocol
                                </p>
                            </div>
                            <button className="relative  overflow-hidden custom-btn flex sm-562:px-[40px] px-[34px] sm-562:py-[25px] py-[20px] justify-center items-center gap-2.5 rounded-[60px] bg-or-btn text-main-bg sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[0.8px] uppercase">
                                Become One
                                <div className="shape3" />
                                <div className="shape4" />
                            </button>
                        </div>
                    </div>
                    <div id="submit-token" className="grid md-991:grid-cols-2 grid-cols-1 sm-420:gap-[48px] gap-[36px]">
                        <div className="flex sm-562:px-[48px] px-[16px] md-991:order-1 order-2 sm-420:gap-[24px] gap-[16px] flex-col justify-center md-991:items-start items-center">
                            <div className="flex flex-col md-991:items-start items-center md-991:text-left text-center sm-420:gap-4 gap-2">
                                <div className="flex px-[16px] py-[8px] justify-center items-center gap-2.5 rounded-[80px] border-[1.5px] border-light-org text-dark-col sm-562:text-[16px] text-[14px] font-medium leading-[110%] tracking-[-0.48px] capitalize">
                                    List Token
                                </div>
                                <p className="text-dark-col md:text-[48px] sm-562:text-[42px] text-[34px] font-medium leading-[110%] tracking-[-1.44px] capitalize">
                                    Submit Token Listing Requests
                                </p>
                                <p className="text-dark-col/70 sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]">
                                    Easily integrate your token into the Werewolf Hunters game. By
                                    doing so, you allow your community to burn tokens in a fun and
                                    interactive way, contributing to deflationary economics.
                                </p>
                            </div>
                            <button className=" relative overflow-hidden custom-btn flex sm-562:px-[40px] px-[34px] sm-562:py-[25px] py-[20px] justify-center items-center gap-2.5 rounded-[60px] bg-or-btn text-main-bg sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[0.8px] uppercase">
                                List Now
                                <div className="shape3" />
                                <div className="shape4" />
                            </button>
                        </div>
                        <div className="flex justify-center md-991:order-2 order-1">
                            <img className src="assets/images/list-token.svg" alt="" />
                        </div>
                    </div>
                    <div className="grid lg-1240:grid-cols-2 grid-cols-1 sm:p-[48px] sm-420:p-[32px] p-[26px] md-991:overflow-x-visible overflow-x-hidden  items-center gap-[48px] relative self-stretch rounded-[48px] bg-dark-col">
                        <div className="lg-1240:order-1 order-2 flex items-center justify-center">
                            <img className="lg-1240:absolute  left-[48px] bottom-[-1px] lg-1240:mb-0 sm:mb-[-48px] sm-420:mb-[-32px] mb-[-26px]" src="assets/images/paid-tasks.png" alt="" />
                        </div>
                        <div className="flex flex-col lg-1240:order-2 order-1  justify-between md:gap-[134px] sm-420:gap-[50px] gap-[30px] items-start self-stretch">
                            <div className>
                                <p className="text-white sm-420:mb-[16px] mb-[12px] md:text-[48px] sm-420:text-[42px] text-[38px] font-medium leading-[110%] tracking-[-1.44px] capitalize">
                                    Create <br />
                                    Paid Tasks
                                </p>
                                <p className="text-white/70 sm-562:text-[18px] sm-420:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.36px]">
                                    Engage users by offering paid tasks that reward them with Burns,
                                    which can be swapped for other tokens or used for exclusive game
                                    boosters. These tasks incentivize participation and increase
                                    exposure to your project.
                                </p>
                            </div>
                            <a href='https://t.me/WerewolfHunters_bot' target='_blank' className="relative overflow-hidden custom-btn flex sm-562:px-[40px] px-[34px] sm-562:py-[25px] py-[20px] justify-center items-center gap-2.5 rounded-[60px] bg-white text-dark-col sm-562:text-[16px] text-[14px] font-medium leading-[92%] tracking-[0.8px] uppercase">
                                Apply Now
                                <div className="shape3" />
                                <div className="shape4" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* section four end */}
            {/* section five start */}
            <section id="about" className="w-full bg-white bg-cover bg-no-repeat bg-center" style={{ backgroundImage: 'url(assets/images/bottom-shad.svg)' }}>
                <div className="max-w-[1240px] mx-auto w-full sm:py-[96px] py-[46px] sm:px-[40px] px-[16px]">
                    <div className="max-w-[840px] mx-auto sm:py-[48px] sm-420:py-[38px] py-0 grid grid-cols-1 text-center gap-[24px]">
                        <p className="text-white md:text-[64px] sm:text-[56px] sm-562:text-[46px] text-[32px] font-medium leading-[100%] tracking-[-1.92px] capitalize">
                            Join the game <br />
                            &amp; turn your token burns <br />
                            into real gains!
                        </p>
                        <p className="text-white/70 sm-562:text-[16px] text-[14px] font-medium leading-[120%] tracking-[-0.32px]">
                            Traditional token burning methods lack engagement and fail to
                            incentivize holders to participate actively. <br />
                            FlameStrike offers a solution by rewarding users for every token
                            burned.
                        </p>
                        <div className="flex items-center justify-center gap-4">
                            <a href="https://www.instagram.com/lycanchain/" target="_blank">
                                <img className="sm-562:w-fit w-[50px]" src="assets/images/instagram.svg" alt="instagram" />
                            </a>
                            <a href="https://t.me/WerewolfHunters_bot" target="_blank">
                                <img className="sm-562:w-fit w-[50px]" src="assets/images/telegram.svg" alt="telegram" />
                            </a>
                            <a href="https://x.com/TheLycanchain" target="_blank">
                                <img className="sm-562:w-fit w-[50px]" src="assets/images/x.svg" alt="x" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* section five end */}
            <Footer />
        </>
    )
}
